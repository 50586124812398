<template>
  <div>
    <div class="custom-header">
      <h3>{{ $t("COMMON.DETAILS") }}</h3>
      <base-button
        class="elite-button add"
        type="button"
        @click="toggleModalState"
      >
        <span class="btn-inner--icon">
          <i class="far fa-plus-circle"></i>
        </span>
        <span class="btn-inner--text">
          {{ $t("USERS.ADD_USERS") }}
        </span>
      </base-button>
    </div>
    <div class="">
      <location-users-view-table :get="get" :filterLocation="location?.id" />
    </div>

    <!-- Modal add slot -->
    <location-users-form
      :location="location"
      :showModal="showModal"
      :get="get"
      @onChangeModalState="toggleModalState"
    />
  </div>
</template>

<script>
import LocationUsersViewTable from "./LocationUsersViewTable.vue";
import LocationUsersForm from "./LocationUsersForm.vue";

export default {
  name: "location-users-view",
  props: ["location", "get"],

  components: {
    LocationUsersViewTable,
    LocationUsersForm,
  },

  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    toggleModalState() {
      this.showModal = !this.showModal;
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
