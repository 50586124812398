<template>
  <div class="elite-tabs-wrapper-content">
    <h3 class="mb-4">{{ location.name }}</h3>
    <div class="resume-list">
      <div class="resume-list-inner">
        <dl class="width_1-2">
          <dt>{{ $t("COMMON.NAME") }}</dt>
          <dd>
            {{ location.name }}
          </dd>
        </dl>
        <dl
          class="width_1-2"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
        >
          <dt>{{ $t("COMMON.MANAGER") }}</dt>
          <dd>
            <users :users="location?.manager?.id ? [location.manager] : []" />
          </dd>
        </dl>
        <dl class="width_1-2">
          <dt>{{ $t("COMMON.EXCERPT") }}</dt>
          <dd>
            <div v-html="location.excerpt"></div>
          </dd>
        </dl>
        <dl
          class="width_1-2"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
        >
          <dt>{{ $t("COMMON.CONTACTS") }}</dt>
          <dd>
            <contacts :contacts="location.contacts" />
          </dd>
        </dl>
        <dl
          class="width_1-2"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt>{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd>
            <organization :organization="location.organization" />
          </dd>
        </dl>
        <dl class="width_1-2">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd v-if="location.created_at">
            {{ $timeZoneDateFormat(location.created_at) }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd v-if="location.updated_at">
            {{ $timeZoneDateFormat(location.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import Users from "@/components/Users.vue";
import Contacts from "@/components/Contacts.vue";

export default {
  name: "location-view-global",

  components: {
    Users,
    Contacts,
  },

  props: ["location"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    location(location) {},
  },
};
</script>
